import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/blog/content"

export const BlogPostTemplate = ({
  data: {
    markdownRemark: {
      htmlAst,
      frontmatter: {
        slug,
        title,
        description,
        headerImage: {
          childImageSharp: { fluid: headerImage },
        },
        headerImageAlt,
        featureImage: {
          childImageSharp: {
            original: { src: ogImage },
          },
        },
        featureImageAlt: ogImageAlt,
      },
    },
  },
}) => {
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        pathname={slug}
        image={ogImage}
        imageAlt={ogImageAlt}
      />
      <Content
        headerImage={headerImage}
        headerImageAlt={headerImageAlt}
        title={title}
        content={htmlAst}
      />
    </Layout>
  )
}

export default BlogPostTemplate

export const blogPostQuery = graphql`
  query BlogPostTemplate($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        slug
        title
        description
        headerImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        headerImageAlt
        featureImage {
          childImageSharp {
            original {
              src
            }
          }
        }
        featureImageAlt
      }
      htmlAst
    }
  }
`
